const accotracStatus = "production";
let baseUrl = "";
if (accotracStatus === "production") {
  baseUrl = "https://accotracapi.joelmuhoho.com";
} else if (accotracStatus === "development") {
  baseUrl = "";
} else {
  baseUrl = "api";
}
export default baseUrl;
